<template>
  <v-container id="data-tables" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="blue"
      icon="mdi-car-limousine"
      inline
      :title="$t('services')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton de Nuevo Servicio -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true"
          >Nuevo Servicio</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="green" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Texto de Busqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo Cancelar -->
      <ConfirmationDialog
        buttonColor1="error"
        buttonColor2="primary"
        buttonText1="NO"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Dialogo Agregar/ Editar Modelos de servicios-->
      <v-dialog max-width="500px" persistent v-model="dialog">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <!-- Servicio -->
                <v-col cols="12">
                  <v-text-field
                    label="Servicio*"
                    required
                    v-model="$v.editedItem.servicio.$model"
                    :error-messages="servicioErrors"
                    @blur="$v.editedItem.servicio.$touch()"
                    @input="$v.editedItem.servicio.$reset(), (changed = true)"
                  ></v-text-field>
                </v-col>
                <!-- descripcion -->
                <v-col cols="12">
                  <v-text-field
                    counter="50"
                    label="Descripción*"
                    required
                    v-model="$v.editedItem.descripcion.$model"
                    :error-messages="descripcionErrors"
                    @blur="$v.editedItem.descripcion.$touch()"
                    @input="$v.editedItem.descripcion.$reset(), (changed = true)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*Campo Obligatorio</small>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox label="Crear otro" v-model="checkbox" :disabled="isEditing"></v-checkbox>
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text :disabled="$v.$invalid" @click="save">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Aqui va progress linear -->
      <v-divider class="mt-3" />

      <!-- Data Table de Servicios  -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="services"
        :loading="isLoading"
        :search.sync="search"
      >
        <!-- Slot de Acciones-->
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <!-- Slot del boton Activo-->
        <template v-slot:item.activo="{ item }">
          <Status :activo="item.activo" />
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!
        </template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="services" :dataFields="fields" fileName="Servicios" />
    </base-material-card>
  </v-container>
</template>

<script>
import { getValidToken } from '@/store/helpers.js'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Status from '@/components/general/StatusInfo.vue'
export default {
  components: {
    ConfirmationDialog,
    DataExporter,
    Status,
  },
  data: () => ({
    //variables
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSure: false,
    overlay: false,
    search: undefined,
    //Arrays
    headers: [
      { text: 'Servicio', align: 'start', sortable: false, value: 'servicio' },
      { text: 'Descripción', value: 'descripcion' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    services: [],
    //Objetos
    defaultItem: {
      _id: '',
      activo: true,
      descripcion: '',
      servicio: '',
    },
    editedItem: {
      _id: '',
      activo: true,
      descripcion: '',
      servicio: '',
    },
    fields: {
      Servicio: 'servicio',
      Descripción: 'descripcion',
      Activo: 'activo',
    },
  }),

  mixins: [validationMixin],

  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      descripcion: { required, minLength: minLength(3), maxLength: maxLength(50) },
      servicio: { required, minLength: minLength(3), maxLength: maxLength(20) },
    },
  },

  computed: {
    //Titulo de los formularios
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Servicio' : 'Editar Servicio'
    },

    // Propiedad computada para errores en campo servicio
    servicioErrors() {
      const errors = []
      if (!this.$v.editedItem.servicio.$dirty) return errors
      !this.$v.editedItem.servicio.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.servicio.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.servicio.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo descripcion
    descripcionErrors() {
      const errors = []
      if (!this.$v.editedItem.descripcion.$dirty) return errors
      !this.$v.editedItem.descripcion.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.descripcion.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.descripcion.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.iniciando()
  },

  methods: {
    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    async BorrarServicio() {
      this.overlay = true
      this.$v.$touch()

      let body = {
        _id: this.editedItem._id,
      }
      let jwt = await getValidToken(this)
      let payload = { jwt: jwt, body: body }
      payload.id = this.editedItem._id
      this.$store
        .dispatch('service/deleteImageService', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se eliminó con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
          })
          this.overlay = false
        })
      Object.assign(this.services[this.editedIndex], this.editedItem)
    },

    /**
     * @description confirma la cancelacion de la edicion en el formulario de mantenimiento
     * de servicios
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de
     * servicios
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el diálogo de mantenimiento de servicios
     */
    close(checkbox) {
      this.isSure = false
      this.checkbox = checkbox
      if (!this.checkbox) {
        this.dialog = false
        this.isEditing = false
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.services.indexOf(item)
      confirm('Esta seguro que desea eliminar este registro?') && this.services.splice(index, 1)
      //this.BorrarServicio()
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar servicios
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.services.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    /**
     * @description Obtiene un listado de todas los servicios Refrescar
     */
    async iniciando() {
      this.obtenerServicios()
    },

    /**
     * @description Obtiene un listado de todas los servicios
     */
    async obtenerServicios() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('service/fetchServices', payload)
        .then((response) => {
          this.services = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Guarda el contenido del formulario ya sea para un servicio nuevo o
     * la edicion de un servicio
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (this.urlImagen == '') {
        this.urlImagen = this.urlSinImagen
      }
      if (!this.$v.$invalid) {
        let body = {
          _id: this.editedItem._id,
          servicio: this.editedItem.servicio,
          descripcion: this.editedItem.descripcion,
          activo: true,
        }

        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('service/editService', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
          Object.assign(this.services[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('service/addService', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.services.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
        }
        this.close((this.checkbox = true))
      }
    },
  },
}
</script>

<style></style>
